import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["payload", "expiration"];

  connect() {
    console.log("XimiTokenController connected");
  }

  updateExpiration() {
    try {
      let payload = JSON.parse(this.payloadTarget.value || "{}");

      const expirationInSeconds = parseInt(this.expirationTarget.value, 10);
      if (expirationInSeconds && !isNaN(expirationInSeconds)) {
        payload.exp = Math.floor(Date.now() / 1000) + expirationInSeconds;
      }

      this.payloadTarget.value = JSON.stringify(payload, null, 2);
    } catch (error) {
      console.error("Invalid JSON in the payload field", error);
    }
  }
}