import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["link", "pane"];

  show(event) {
    if (event) event.preventDefault();
    this.linkTargets.forEach(link => link.classList.remove("selected"));

    const selectedLink = event.currentTarget;
    selectedLink.classList.add("selected");

    this.paneTargets.forEach(pane => pane.classList.remove("active"));

    const targetPaneId = selectedLink.dataset.paneId;
    const targetPane = this.paneTargets.find(pane => pane.dataset.paneId === targetPaneId);

    if (targetPane) {
      targetPane.classList.add("active");
    } else {
      console.error(`Pane with data-pane-id "${targetPaneId}" not found.`);
    }
  }
}