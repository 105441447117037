import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["colorPreview", "backgroundColorPicker", "textColorPicker"]

  connect() {
    console.log('Color Input Controller Connected');
    this.updateBackgroundColor(); // Met à jour la couleur du fond au chargement
    this.updateTextColor(); // Met à jour la couleur du texte au chargement
  }

  updateBackgroundColor() {
    const selectedBackgroundColor = this.backgroundColorPickerTarget.value;
    this.colorPreviewTarget.style.backgroundColor = selectedBackgroundColor;
  }

  updateTextColor() {
    const selectedTextColor = this.textColorPickerTarget.value;
    this.colorPreviewTarget.style.color = selectedTextColor;
  }
}
